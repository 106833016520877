
// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
//
//// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }
//
//// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }
//
//// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }
//
//// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }

//@include media-breakpoint-up(sm) {}
//@include media-breakpoint-up(md) {}
//@include media-breakpoint-up(lg) {}
//@include media-breakpoint-up(xl) {}

@include media-breakpoint-down(xs) {  }
@include media-breakpoint-down(sm) {
    .main-header {
        padding: 0 30px;
    }

    .row {
        margin: 0;
    }
    #home {
        & .garage-image-container{
            margin-top: 30px;
        }

        & .card-container {
            justify-content: center;
            margin-bottom: 0;
            & .card {
                margin-bottom: 30px;
            }
        }
    }

    #services {
        margin-top: 15px!important;

        & .blue-bg {
            & .round-top {
                width: auto;
            }
        }

        & .image-container {
            justify-content: center;
        }

        & .first-content-container {
            margin: 0;
            //padding: 15px ;
            & .mt-3 {
                display: flex;
                justify-content: center;
            }
        }

        & .row {
            &.mobile-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }

    #numbers {
        & .numbers-container {
            > .row {
                display: flex;
                flex-direction: column;
            }
            & .number {
                text-align: left;
            }
        }
    }

    #worth-it {
        & .content-info-container {
            & .content-info {
                margin-left: 0;
            }
            & .pocket-container {
                & .line_2 {
                    transform: translate(20px, -40px);
                }
            }
        }
    }

    #pricing {

        & .justify-content-start {
            padding: 0;
        }
        & .justify-content-end {
            padding: 0;
        }

        & .card {
            & .card-body {
                padding: 30px 10px;

                & .card-content {
                    padding: 10px 0 30px 0;
                }
            }
        }

        & .discount-container {
            & .discount-text {
                height: auto;
            }
        }
    }

    #contact-us {
        & .contact-us-content {
            padding: 25px 15px;
            font-size: 28px;
        }
    }

    #google-map {
        min-width: 340px;
        max-height: 340px;
    }

    .container-fluid {
        &.footer {
            width: 100%;
            margin: 0;
            padding: 15px 0 0 0;
        }
    }
}
@include media-breakpoint-down(md) {
    .main-container {
        padding-top: 70px;
    }

    .main-header {
        & .navbar {
            width: 100%;
            max-width: 720px;
            padding: 0.5rem 0;
            & .navbar-brand {
                transform: translateX(0);
            }
            & .navbar-collapse {
                & .navbar-nav {
                    background: white;
                    padding: 0 30px;
                    width: auto;
                    float: right;
                    & .nav-item {
                        &.dropdown {
                            justify-content: flex-start;
                            & .dropdown-menu {
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }
        &.bg-scroll {
            .navbar {
                .navbar-collapse {
                    & .navbar-nav {
                        background: transparent;
                    }
                }
            }
        }
    }

    .card-container {
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #services {
        & .first-content-container {
            z-index: 10;
            &.mb-3-md {
                margin-bottom: 30px;
            }
        }
    }

    #numbers {
        &.mt-5 {
            margin-top: 0!important;
        }

        & .numbers-container {
            padding: 25px;
            margin-top: 15px;
        }

        & .tyre-image-container {
            display: none;
        }
    }

    #worth-it {
        & .content-info-container {
            &.second {
                padding-right: 0;
            }
            &.first {
                padding-left: 0;
            }
        }
        & .image-container {
            & .image_line_arrow_down {
                margin-bottom: 30px;
            }
        }
    }

    #pricing {
        & .col-md-12.justify-content-start {
            justify-content: center!important;
            margin-bottom: 30px;
        }

        & .col-md-12.justify-content-end {
            justify-content: center!important;
        }
    }
}
@include media-breakpoint-down(lg) {  }

@include media-breakpoint-only(xs) {  }
@include media-breakpoint-only(sm) {  }
@include media-breakpoint-only(md) {

}
@include media-breakpoint-only(lg) {  }
@include media-breakpoint-only(xl) {  }
