/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 19:13:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-right {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 84% 50%;
        transform-origin: 84% 50%;
    }
    100% {
        -webkit-transform: scale(1.25) translateX(20px);
        transform: scale(1.25) translateX(20px);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}
@keyframes kenburns-right {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 84% 50%;
        transform-origin: 84% 50%;
    }
    100% {
        -webkit-transform: scale(1.25) translateX(20px);
        transform: scale(1.25) translateX(20px);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-16 19:15:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}
@keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@-webkit-keyframes lightSpeedIn {
    0% {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}
@keyframes lightSpeedIn {
    0% {
        -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }
    60% {
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes float {
    0% {
        //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
        transform: translate(0px, 0px);
    }
    50% {
        //box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
        transform: translate(0px, -10px);
    }
    100% {
        //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
        transform: translate(0px, 0px);
    }
}

@keyframes headerBg {
    from {background-color: $white;}
    to {background-color: $secondary;}
}

.float {
    animation: float 10s ease-in-out infinite;
    -webkit-animation: float 10s ease-in-out infinite;;
}

.kenburns-right {
    -webkit-animation: kenburns-right 5s ease-out both;
    animation: kenburns-right 5s ease-out both;
}

.shake-horizontal {
    -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.lightSpeedIn {
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
