
* {
    font-family: 'Inter', sans-serif;
}

html, body {
    background-color: $white;
    min-height: 100vh;
}

#app-body {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 0;
    margin: 0;
    overflow: hidden;
    max-width: 100vw;
}

// Cookie - START
.cc_overlay_lock {
    & .cc_dialog {
        & .cc_dialog_headline {

        }

        & .cc_dialog_text {

        }

        & .cc_b_ok {
            background-color: $primary!important;
            color: $white!important;
        }

        & .cc_b_cp {
            color: $secondaryLight!important;
        }
    }
}

.cookie-consent-preferences-overlay {
    & .cookie-consent-preferences-dialog {
        & .cc_cp_container {
            & .cc_cp_head {

            }

            & .cc_cp_content {

            }
            & .cc_cp_footer {
                & .cc_cp_f_save {
                    & button {
                        background-color: $primary!important;
                        color: $white!important;
                    }
                }
            }
        }
    }
    & input[type="checkbox"].checkbox_cookie_consent:checked+label:before {
        background: $primary!important;
    }
}
// Cookie - END

a {
    color: unset;
    text-decoration: none;
}

.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    display: none;
    border-radius: 50%;
    z-index: 99;
}

.main-header {
    //margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 80px;
    #atm-navbar {
        justify-content: flex-end;
        margin-left: 60px;
    }

    .navbar {
        padding-left: 0px;
        padding-right: 0px;
        align-items: baseline;
        background-color: inherit;
        &.fixed-top {
            right: unset;
            left: unset;
        }
        .navbar-brand {
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 44px;
            color: $primary;
            transform: translateX(-80px);
        }

        .navbar-toggler {

        }

        .navbar-collapse {
            background-color: inherit;
            .navbar-nav {
                .nav-item {
                    font-style: normal;
                    font-weight: 900;
                    font-size: 14px;
                    color: $secondary;

                    &.dropdown {
                        min-width: 150px;
                        width: 150px;
                        display: flex;
                        justify-content: flex-end;
                    }
                    .nav-link {
                        color: $secondary;
                        &:hover {
                            color: $primary;
                            //animation: shake 0.8s;
                            //animation-iteration-count: infinite;
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            //background: $primary;
            border-color: $secondary;
            color: $primary;
            & .navbar-toggler-icon {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2840, 40, 40, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
        }
    }
    &.bg-scroll {
        background-color: $secondary;
        animation: headerBg 1s linear;
        .navbar {
            .navbar-collapse {
                .nav-item {
                    color: $white;
                    .nav-link {
                        color: $white;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
            .navbar-brand {
                color: $white;
            }
            .navbar-toggler {
                & .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
                }
            }
        }
    }
}

.footer {
    margin-top: 120px;
    padding: 30px 50px;
    width: auto;
    color: $white;
    background-color: $secondaryLight;

    .first {

    }

    .middle {
    }

    .last {
    }

    .footer-brand {
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 30px;
        text-align: center;
    }

    .footer-logo {
        text-align: center;

        > a > img {
            max-height: 80px;
            height: 80px;
        }
    }

    .footer-slogan {
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 45px;
        text-align: center;
    }

    .cookie-settings {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        text-align: right;

        color: rgba(255, 255, 255, 0.6);
    }

    .copy-right-text {
        margin-top: 30px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        flex-direction: column;
        text-align: center;
        span {
            font-style: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            margin-bottom: 5px;
        }

        a {
            font-style: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;

            &:hover {
                color: currentColor;
            }
        }
    }

    .fb-like {
        width: 177px !important;

        span {
            width: 177px !important;

            iframe {
                width: 176px !important;
            }
        }
    }
}

.main-container {
    min-height: 100vh;
    padding-top: 80px;
}

.row-margin {
    margin-bottom: 80px;
}

.row-margin-20 {
    margin-bottom: 20px;
}

.content-title-text {
    color: $secondary;
    font-family: Inter, 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    padding: 15px 0;
}

.title-text {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 45px;
    color: $secondary;
}

.first-title {
    width: 250px;
    line-height: 50px;
    padding: 15px 0;
}

.content-normal-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;

    color: #282828;
}

.content-description-container {

}

.content-number {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    color: $primary;
}

.content-description-text {
    background: $white;
    box-shadow: 8px 11px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 15px;
    z-index: 99;
    position: relative;
    > .text {
        font-family: Inter, 'sans-serif';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: $black;
    }
}

.image-container {
    z-index: 1;
    position: relative;

    &.tyres {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(40%, -35%);
        transition-duration: 3s;
    }
    &.auto {
        right: unset;
        top: unset;
    }
}

.content-info-container {
    position: relative;
    > span.pocket-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: absolute;
        > .pocket {
            width: 35px;
            height: 35px;
            position: absolute;
            transform: translate(50%, -50%);
        }

        > .line {
            width: 130px;
            height: 30px;
            transform: translate(0%, 150%);
            position: absolute;
        }
    }

    .content-info {
        margin-left: 80px;
        padding: 15px;
        position: relative;
        background: $info;
        //box-shadow: 8px 11px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        transform: matrix(1, -0.03, 0.02, 1, 0, 0);
        > .text {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: $black;
            transform: matrix(1, -0.03, 0.02, 1, 0, 0);
        }
    }
}

.garage-image-container {
    display: flex;
    justify-content: flex-end;
    & .car_verification {
        position: relative;
        top: 50px;
    }
}

.first-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-demo {
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    border-radius: 60px;
    padding: 10px 20px;
    width: 125px;
    background-color: $primary;
    border-color: $primary;
    &:hover {
        background-color: $secondary;
        border-color: $secondary;
    }
}

.card-container{
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .card {
        cursor: pointer;
        width: 300px;
        display: flex;
        flex-direction: column;
        height: fit-content;
        background: $white;
        box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        border: unset;
        .card-body {
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 15px;
                > img {
                    width: 54px;
                }
            }
        }
        .card-title {
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: $secondary;
            padding: 20px 0;
        }

        .card-content {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: $secondary;
        }
        &:hover {
            box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 20%);
        }

    }
}

.blue-bg {
    color: $white;
    background-color: $primary;
    width: 100%;

    > .container {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .image-container {
        display: flex;
        justify-content: flex-start;
        > img {
            display: flex;
            border-radius: 20px;
            transform: translateX(-70px);
        }
    }

    .first-content-container {

    }

    .content-title-text {
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        color: $white;
    }

    .content-normal-text {
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: $white;
        max-width: 500px;
    }

    .round-bottom {
        bottom: -1px;
        position: relative;
        margin-top: -100px;
        width: 100%;
        > svg {
            width: 100%;
            transform: rotate(180deg);
        }
    }
    .round-top {
        position: relative;
        width: 100%;
        top: -2px;
        > svg {
            width: 100%;
        }
    }
    margin-bottom: 150px;
}

.btn-info {
    background: $info;
    border-radius: 60px;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    //line-height: 24px;
    text-align: center;
    color: $secondaryLight;
    min-width: 150px;
    //height: 50px;
    padding: 0 30px;
    line-height: 58px;
    &:hover {
        color: $white;
        background-color: $primary;
        border-color: $primary;
    }
}

.btn-default {
    background: rgba(188, 187, 187, 0.92);
    border-radius: 60px;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 47px;
    text-align: center;
    color: $secondaryLight;
    min-width: 150px;
    height: 50px;
    padding: 0 15px;
    &:hover {
        color: $white;
        background-color: $primary;
        border-color: $primary;
    }
}

.numbers-container {
    background: #FFFFFF;
    box-shadow: 3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1);
    border-radius: 6.76924px;
    padding: 25px 40px;
    margin-top: 30px;
    height: fit-content;
    > .row {
        padding-top: 10px;
        padding-bottom: 10px;
        .number {
            font-weight: 900;
            font-size: 25px;
            line-height: 30px;
            text-align: right;
            color: $primary;
            width: 125px;
            padding-right: 20px;
        }

        .content-normal-text {
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            color: $secondary;
            width: auto;
            padding-left: 10px;
        }
    }
}
.hover-effect {
    &:hover {
        box-shadow: 10px 10px 24px 0px rgb(0 0 0 / 10%)!important;
        cursor: pointer;
    }
}
.tyre-image-container {
    display: flex;
    margin-top: 40px;
}

.text-box {
    background: #FFFFFF;
    box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 25px 30px;
    > p {
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: $secondary;
    }
}

#home {

}

#services {
    .image-container {
        .loyalty_card {
            position: relative;
        }
        .laptop_working {
            position: relative;
        }
    }
}

#numbers {

}

#about-us {

}

#worth-it {
    .content-info-container {
        margin-bottom: 55px;
        &.first {
            padding-left: 150px;
        }
        &.second {
            padding-right: 150px;
        }

       .pocket-container {
           .line_2 {
               transform: translate(20px, -15px);
           }
       }
        .content-info {
            .text {

            }
        }
    }
    .example {
        .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            &.arrow {

            }

            &.app-sh {

            }
        }
    }

}

#pricing {
    .card {
        width: 480px;
        .card-body {
            padding: 30px;
            .icon {
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
            }
            .card-title {
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
            }

            .card-content {
                padding: 10px 30px 30px 10px;
                .list-group {
                    padding-left: 30px;
                    .list-group-item {
                        display: list-item;
                        padding: 0;
                        list-style-position: outside;
                        border: none;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 22px;
                        &.active {
                            background-color: $white;
                            color: $primary;
                        }
                        &::marker {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .discount-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        position: relative;
        margin-bottom: 80px;
        .line_discount {
            position: absolute;
            left: -90px;
            bottom: -50px;
        }
        .discount-text {
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: $primary;
            background: $white;
            box-shadow: 8px 11px 20px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            transform: matrix(1, -0.05, 0.04, 1, 0, 0);
            padding: 20px 30px;
            height: 86px;
            max-width: 440px;
        }
    }
}

#contact-us {

    .contact-card {
        background: #FFFFFF;
        box-shadow: 3.38462px 3.38462px 13.5385px rgba(0, 0, 0, 0.1);
        border-radius: 6.76924px;
        padding: 20px 30px;
        height: 70px;
        min-width: 285px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        cursor: pointer;
        color: $secondaryLight;
        &:hover {
            background-color: $primary;
            color: $white
        }
        &.active {
            background-color: $primary;
            color: $white
        }
        .contact-icon {
            margin-right: 35px;
            i {
                font-size: 30px;
            }
        }

        .contact-text {
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 29px;
            color: inherit;
        }
    }

    .contact-us-content {
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 48px;
        color: $primary;
        padding: 25px;
        &.map-container {
            padding: 0;
        }
    }

}

#contact-us-collapsible {
    .collapse {
        box-shadow: 8px 11px 20px rgb(0 0 0 / 15%);
        transform: matrix(1, -0.05, 0.04, 1, 0, 0);
        transition-duration: 3s;
        margin-top: 30px;
        margin-bottom: 50px;
    }
}

#google-map {
    width: fit-content;
    position: relative;
    min-height: 400px;
    min-width: 600px;
    display: block;
}

.modal-dialog {
    .modal-content {
        .modal-body {
            display: flex;
            justify-content: center;
            iframe {
                height: 75vh;
                width: 100%;
                border: none;
            }
        }
    }
}

#privacy-modal {

}

.logo {
    &.header-logo {
        height: 55px;
        max-height: 55px;
    }
}
