:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: theme-color("primary");
    --secondary: theme-color("secondary");
    --success: theme-color("success");
    --info: theme-color("info");
    --warning: theme-color("warning");
    --danger: theme-color("danger");
    --light: theme-color("primary");
    --dark: theme-color("dark");;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    //--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-sans-serif: 'Inter';
    font-family: 'Inter', sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}


@supports (font-variation-settings: normal) {
    :root { font-family: 'Inter var', sans-serif; }
    html { font-family: 'Inter var', sans-serif; }
}
