

.image_line_arrow_down {
    background-image: url('/images/content/line_arrow_down.png');
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    width: 260px;
}

.app_preview {
    background-image: url("/images/content/app_preview.png");
    background-size: initial;
    background-position: top;
    background-repeat: no-repeat;
    height: 341px;
    width: 466px;
    box-shadow: -20px 20px 20px rgb(0 0 0 / 25%)
}

.line_info {
    background-image: url("/images/content/line_info.png");
    background-size: initial;
    background-position: top;
    background-repeat: no-repeat;
    height: 125px;
    width: 125px;
}

.bag {
    background-image: url("/images/icons/bag.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 80px;
    height: 50px;
}

.line_discount {
    background-image: url("/images/content/line_discount.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    height: 60px;
    width: 220px;
    transform: matrix(1, 0.05, -0.04, 1, 0, 0);
}

.apostrof {
    background-image: url("/images/content/apostrof.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 50px;
}

.realistic-complete-set-car-wheels {
    background-image: url("/images/content/realistic-complete-set-car-wheels.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 350px;
    height: 320px;
}

.loyalty_card {
    background-image: url("/images/content/loyalty_card.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 310px;
    height: 440px;
}

.car_verification_1 {
    background-image: url("/images/content/car_verification_1.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 310px;
    height: 440px;
    border-radius: 30px;
}

.laptop_working {
    background-image: url("/images/content/laptop_working.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 310px;
    height: 440px;
}

.bag {
    background-image: url("/images/icons/bag.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
}

.icon_money {
    background-image: url("/images/icons/icon_money.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 90px;
    height: 60px;
}

.table_image {
    background-image: url("/images/icons/table.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
}

.pinguin {
    background-image: url("/images/icons/pinguin.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
}

.car_verification {
    background-image: url("/images/content/car_verification.png");
    background-size: initial;
    background-position: center;
    background-repeat: no-repeat;
    width: 400px;
    height: 430px;
    filter: drop-shadow(-25px 0px 44px rgba(51, 77, 219, 0.37));
}
